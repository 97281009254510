import { TRACK_EVENT } from '@tkww/the-bash-frontend';

import {
  ARTICLE_BODY_CLICK_AREA,
  BUTTON_CTA_STYLE,
  COMPONENT_VIEWED,
  CTA_CLICK,
  HERO_CLICK_AREA,
  HYPERLINK_CTA_STYLE,
  ICON_CTA_STYLE,
  NAVIGATION_CTA_STYLE,
  PREMIUM_VENUES_CLICK_AREA,
  PREMIUM_VENUES_SUCCESS_CLICK_AREA,
  PROFILE_RELATED_SPACES_CLICK_AREA,
  PROFILE_SUMMARY_CLICK_AREA,
  REQUEST_FREE_QUOTE_CLICK_TARGET,
  TAB_CTA_STYLE,
  PARTY_ENTRY_POINT_SUBMISSION,
  NOT_A_USER_ACTION,
  CREATE_ACCOUNT_CLICK_AREA,
} from 'analytics/constants';
import { venueServiceSlugs } from 'components/VenueContactWizard/constants';

export { relatedProfileTileClick } from './profile';

export const trackEvent = (name, additionalProperties = {}) => ({
  type: TRACK_EVENT,
  name,
  properties: {
    ...additionalProperties,
  },
});

export const ctaClickEvent = (clickArea, clickTarget, additionalProperties = {}) => ({
  type: TRACK_EVENT,
  name: CTA_CLICK,
  properties: {
    clickArea,
    clickTarget,
    ...additionalProperties,
  },
});

export const vendorTileClickEvent = (vendor, featuredVendor = false) => ({
  type: TRACK_EVENT,
  name: 'Vendor Tile Click',
  properties: {
    conceptScheme: vendor.conceptScheme,
    conceptName: vendor.primaryConceptTitle,
    vendorIdBash: vendor.id,
    vendorNameBash: vendor.profileName,
    featuredVendor,
  },
});

export const vendorCtaClick = (ctaClicked, vendor) => {
  const { id, gmMemberId, conceptScheme } = vendor;
  const primaryConcept = vendor.concepts.find((c) => c.isPrimary);

  return {
    type: TRACK_EVENT,
    name: CTA_CLICK,
    properties: {
      conceptScheme,
      ctaClicked,
      primaryConceptId: primaryConcept.id,
      primaryConceptName: primaryConcept.title,
      vendorId: gmMemberId,
      vendorIdBash: id,
    },
  };
};

export const vendorServiceTileClick = ({ id, service, ...additionalProperties }) => ({
  type: TRACK_EVENT,
  name: 'Vendor Service Tile Click',
  properties: {
    vendorServiceId: id,
    vendorService: service,
    ctaStyle: BUTTON_CTA_STYLE,
    ...additionalProperties,
  },
});

export const vendorServiceLinkClick = ({ id, service, ...additionalProperties }) => ({
  type: TRACK_EVENT,
  name: 'Vendor Service Tile Click',
  properties: {
    vendorServiceId: id,
    vendorService: service,
    ctaStyle: HYPERLINK_CTA_STYLE,
    ...additionalProperties,
  },
});

export const themeIngredientMarketplaceClick = (themeName, ingredient, vendorCategory) => ({
  type: TRACK_EVENT,
  name: 'Click to Marketplace',
  properties: {
    themeName,
    vendorCategory,
    ingredientType: ingredient.type,
    clickTarget: 'Explore All Link',
  },
});

export const themeIngredientVendorMarketplaceClick = (ingredient, vendor) => ({
  type: TRACK_EVENT,
  name: 'Click to Marketplace',
  properties: {
    ingredientId: ingredient.id,
    ingredientName: ingredient.title,
    ingredientType: ingredient.type,
    vendorIdBashClick: vendor.id,
    vendorNameClick: vendor.profileName,
    clickTarget: 'Theme-Ingredient Vendor Tile',
  },
});

export const profileContactVendorClick = ({ clickArea, vendor } = {}) => ({
  type: TRACK_EVENT,
  name: 'Click To Contact',
  properties: {
    clickArea,
    clickTarget: REQUEST_FREE_QUOTE_CLICK_TARGET,
    ctaStyle: BUTTON_CTA_STYLE,
    locationCity: vendor.cityName,
    locationState: vendor.stateAbbrev,
    memberId: vendor.id,
    venueId: vendor.venueId,
    vendorPrimaryCategory: vendor.primaryServiceTypeName,
  },
});

export const searchTileContactVendorClick = ({
  clickTarget,
  clickArea,
  vendor,
  pageType,
} = {}) => ({
  type: TRACK_EVENT,
  name: 'Click To Contact',
  properties: {
    clickArea,
    clickTarget,
    ctaStyle: BUTTON_CTA_STYLE,
    locationCity: vendor.cityName,
    locationState: vendor.stateOrProvinceAbbreviation,
    memberId: vendor.memberID,
    searchResultsType: pageType,
    venueId: vendor.venueId,
    vendorPrimaryCategory: vendor.primaryServiceName,
    firstAiDescription: vendor.firstAIAssistedDescriptionDate,
    mostRecentAiDescription: vendor.lastAIAssistedDescriptionDate,
  },
});

export const venueAvailabilityProfileClick = ({ clickArea, venueProfile } = {}) => ({
  type: TRACK_EVENT,
  name: 'Click To Contact',
  properties: {
    clickTarget: 'Check Availability',
    clickArea,
    ctaStyle: BUTTON_CTA_STYLE,
    locationCity: venueProfile.cityName,
    locationState: venueProfile.stateOrProvinceName,
    memberId: venueProfile.id,
    primaryCategory: venueProfile.primaryServiceType.personForm,
    secondaryCategories: venueProfile.providedServiceTypes.map((service) => service.personForm),
    topLevelCategory: venueProfile.primaryServiceType.topLevelServiceType,
    venueId: venueProfile.venue.id,
    venueServicesOffered: venueProfile.serviceAttributes.some((sa) =>
      venueServiceSlugs.includes(sa.slug)
    ),
  },
});

export const profileHeroImageClick = (profile) => {
  const eventProperties = {
    clickArea: HERO_CLICK_AREA,
    memberId: profile.id,
  };

  if (profile.venue) {
    eventProperties.venueId = profile.venue.id;
  }

  return {
    type: TRACK_EVENT,
    name: 'Image Tap',
    properties: {
      ...eventProperties,
    },
  };
};

export const profileMediaButtonCtaClick = (profile, isPhotosButton) => {
  const eventProperties = {
    clickTarget: `See All ${isPhotosButton ? 'Photos' : 'Media'}`,
    clickArea: HERO_CLICK_AREA,
    ctaStyle: BUTTON_CTA_STYLE,
    memberId: profile.id,
  };

  if (profile.venue) {
    eventProperties.venueId = profile.venue.id;
  }

  return {
    type: TRACK_EVENT,
    name: CTA_CLICK,
    properties: {
      ...eventProperties,
    },
  };
};

export const profileTabClick = (profile, tab) => {
  const eventProperties = {
    clickTarget: tab,
    clickArea: HERO_CLICK_AREA,
    ctaStyle: NAVIGATION_CTA_STYLE,
    memberId: profile.id,
  };

  if (profile.venue) {
    eventProperties.venueId = profile.venue.id;
    eventProperties.clickArea = 'About';
    eventProperties.ctaStyle = TAB_CTA_STYLE;
  }
  return {
    type: TRACK_EVENT,
    name: CTA_CLICK,
    properties: {
      ...eventProperties,
    },
  };
};

export const profileMediaVideoPlay = (videoHost, videoId) => ({
  type: TRACK_EVENT,
  name: 'Video Play',
  properties: {
    clickTarget: 'Play',
    clickArea: 'Profile Media',
    videoHost,
    videoId,
  },
});

export const profileCtaClick = (profile, clickTarget) => ({
  type: TRACK_EVENT,
  name: CTA_CLICK,
  properties: {
    clickArea: PROFILE_SUMMARY_CLICK_AREA,
    clickTarget,
    ctaStyle: HYPERLINK_CTA_STYLE,
    venueId: profile.venue.id,
    memberId: profile.id,
  },
});

export const relatedSpaceTileClick = (clickTarget, relatedSpace) => ({
  type: TRACK_EVENT,
  name: 'Profile Tile Click',
  properties: {
    clickArea: PROFILE_RELATED_SPACES_CLICK_AREA,
    clickTarget,
    category: relatedSpace.primaryServiceType.name,
    locationCity: relatedSpace.cityName,
    locationState: relatedSpace.stateOrProvinceName,
    memberId: relatedSpace.id,
    profileType: 'Venue',
    topLevelCategory: 'Venues',
    venueId: relatedSpace.venueId,
    venueName: relatedSpace.profileName,
  },
});

export const premiumProfileTileClick = (profile, successPage) => ({
  type: TRACK_EVENT,
  name: 'Profile Tile Click',
  properties: {
    clickArea: successPage ? PREMIUM_VENUES_SUCCESS_CLICK_AREA : PREMIUM_VENUES_CLICK_AREA,
    locationCity: profile.cityName,
    locationState: profile.stateOrProvinceName,
    memberId: profile.memberID,
    primaryCategory: profile.primaryServiceName,
    profileName: profile.profileName,
    profileType: 'Venue',
    secondaryCategory: null,
    topLevelCategory: 'Venues',
    venueId: profile.venueId,
  },
});

export const socialMediaCtaClick = (profile, socialMediaName) => ({
  type: TRACK_EVENT,
  name: CTA_CLICK,
  properties: {
    clickArea: 'About',
    clickTarget: socialMediaName,
    ctaStyle: HYPERLINK_CTA_STYLE,
    memberId: profile.id,
    venueId: profile.venue.id,
  },
});

export const socialShareCtaClick = (socialMediaName) => ({
  type: TRACK_EVENT,
  name: CTA_CLICK,
  properties: {
    clickTarget: socialMediaName,
    ctaStyle: ICON_CTA_STYLE,
    clickArea: 'Social Share',
  },
});

export const viewMoreCtaClick = (clickArea, isExpanded) => ({
  type: TRACK_EVENT,
  name: CTA_CLICK,
  properties: {
    clickTarget: isExpanded ? 'View Less' : 'View All',
    ctaStyle: HYPERLINK_CTA_STYLE,
    clickArea,
    pageContext: 'Profile',
  },
});

export const hubspotChatInitiation = (contentType) => ({
  type: TRACK_EVENT,
  name: 'Chat Interaction',
  properties: {
    contentType,
    interactionType: 'chat initiated',
  },
});

export const breadcrumbCtaClick = (index) => ({
  type: TRACK_EVENT,
  name: CTA_CLICK,
  properties: {
    clickTarget: `Dir ${index}`,
    clickArea: 'Breadcrumbs',
    ctaStyle: HYPERLINK_CTA_STYLE,
  },
});

export const entryPointCtaClick = () => ({
  type: TRACK_EVENT,
  name: CTA_CLICK,
  properties: {
    clickArea: 'My Party Promo',
    clickTarget: 'Get My Tips',
    ctaStyle: BUTTON_CTA_STYLE,
  },
});

export const entryPointSubmission = ({ articleName, articleTopic }) => ({
  type: TRACK_EVENT,
  name: PARTY_ENTRY_POINT_SUBMISSION,
  properties: {
    articleName,
    articleTopic,
  },
});

export const articleTileClick = ({
  servicePageType,
  serviceCategory,
  articleName,
  articleSlug,
  ctaStyle,
  ...rest
}) => ({
  type: TRACK_EVENT,
  name: 'Article Tile Click',
  properties: {
    servicePageType,
    serviceCategory,
    articleName,
    articleSlug,
    ctaStyle,
    ...rest,
  },
});

export const plannerValuePropositionOurGuaranteeClick = ({
  servicePageType,
  serviceCategory,
  searchResultsType,
  searchResultsFormat,
  category,
  location,
}) => ({
  type: TRACK_EVENT,
  name: CTA_CLICK,
  properties: {
    clickTarget: 'our guarantee',
    clickArea: 'value proposition',
    servicePageType,
    serviceCategory,
    searchResultsType,
    searchResultsFormat,
    category,
    location,
  },
});

export const createAccountEntryPointClick = ({ clickTarget }) =>
  ctaClickEvent(CREATE_ACCOUNT_CLICK_AREA, clickTarget, {
    ctaStyle: BUTTON_CTA_STYLE,
  });

export const componentViewed = ({ component }) => ({
  type: TRACK_EVENT,
  name: COMPONENT_VIEWED,
  properties: {
    component,
    ...NOT_A_USER_ACTION,
  },
});
